<template>
  <button class="btn">
    <div class="btn__icon--before" v-if="$slots['icon-before']">
      <slot name="icon-before" />
    </div>
    <slot>Button</slot>
    <div class="btn__icon--after" v-if="$slots['icon-after']">
      <slot name="icon-after" />
    </div>
  </button>
</template>

<style lang="scss" scoped>
.btn {
  font-size: 1rem;
  font-family: 'Rubik', sans-serif;
  background-color: var(--background-color-elevated-1);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  user-select: none;
  cursor: pointer;
  transition: background 80ms ease;

  &:hover {
    background-color: var(--background-color-elevated-2);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--before {
      @extend .btn__icon;
      margin-right: 0.75rem;
    }

    &--after {
      @extend .btn__icon;
      margin-left: 0.75rem;
    }
  }
}
</style>
