
import { defineComponent } from 'vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'PageNotFound',
  components: { Button },
  methods: {
    inspect() {
      this.$router.push('/');
    },
  },
});
